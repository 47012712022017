<template>
  <b-modal
    id="new-folder-modal"
    ref="new-folder-modal"
    centered
    title="Create New Folder"
    ok-title="Save Changes"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @hidden="resetModal"
    @ok="create"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <validation-observer
        ref="createNewFolderRules"
        tag="form"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  placeholder="Name here"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BOverlay,
} from 'bootstrap-vue'

import useFoldersList from '@/views/components/folders-list/useFoldersList'

export default {
  name: 'NewFolderModal',
  components: {
    ValidationProvider,
    ValidationObserver,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
  },
  props: {
    folderType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      name: '',
      required,
    }
  },
  setup() {
    const {
      createFolder,
    } = useFoldersList()

    return {
      createFolder,
    }
  },
  methods: {
    resetModal() {
      this.name = ''
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise(() => {
        this.$refs.createNewFolderRules.validate().then(success => {
          if (success) {
            const queryParams = {
              name: this.name,
              type: this.folderType,
            }

            this.createFolder(queryParams).then(() => {
              this.$emit('refetchData')
              this.$bvModal.hide('new-folder-modal')
            })
          }
        })
      })
    },
  },
}
</script>
